import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";
import {handleBookingTitle} from "../../plugins/handleDate";

const state = {
    visibelImage: false,
    blankBooking: {
        store: {
            id: '',
            name: '',
            image_url: ''
        },
        therapist: {
            id: '',
            name: '',
            image_url: ''
        },
        start_time: '',
        service: {
            id: '',
            duration: '',
            name: '',
            booking_type: '',
            service_id: ''
        },
        selectedOptions: [],
        step: 1,
        selectedEvents: [{
            formatedTitle: '',
            start_time: ''
        }],
        selectedMultipleEvents: [
            {
                formatedInfo: '-',
                formatedTitle: '',
                start_time: ''
            },
            {
                formatedInfo: '-',
                formatedTitle: '',
                start_time: ''
            },
            {
                formatedInfo: '-',
                formatedTitle: '',
                start_time: ''
            }
        ],
        options: [{
            id: '',
            title: '',
            duration: ''
        }]
    },
    currentBooking: {
        count_in_queue: 0,
        store: {
            id: '',
            name: '',
            image_url: ''
        },
        store_basic_setting: {
            pre_caution_after_booking: ''
        },
        therapist: {
            id: '',
            name: '',
            image_url: ''
        },

        start_time: '',
        service: {
            id: '',
            duration: '',
            name: '',
            booking_type: '',
            service_id: ''
        },
        selectedOptions: [],
        step: 1,
        selectedEvents: [{
            start_time: ''
        }],
        options: [{
            id: '',
            title: '',
            duration: ''
        }]
    },
    adminFilterBooking: {
        booking_type: '',
        statuses: [],
        query: ''
    },
    bookings: [],
    bookingStatuses: [],
    activeNomalBookingComponent: {
        list: true,
        show: true,
        new: true,
        update: true
    },
    editNomalBooking: {
        customer: {},
        booking_route: {},
        booking_times: [{ start_time: ''}],
        booking_type: {},
        options: [],
        service: {},
        status: {}
    }
}

const mutations = {
    SET_VISIBLE_IMAGE(state, payload){
        state.visibelImage = payload
    },
    SET_INIT_AWAIT_BOOKING_FILTER(state) {
        state.adminFilterBooking = {
            booking_type: 'await_booking',
            statuses: [],
            query: ''
        }
    },
    SET_INIT_NORMAL_BOOKING_FILTER(state) {
        state.adminFilterBooking = {
            booking_type: '',
            statuses: [],
            query: ''
        }
    },
    SET_BOOKINGS(state, payload) {
        state.bookings = payload
    },
    SET_CURRENT_BOOKING(state, payload) {
        state.currentBooking = {...payload}
    },
    FILTER_CURRENT_BOOKING(state, payload) {
        state.currentBooking = Object.assign({}, state.currentBooking, payload)
    },
    SET_BOOKING_STATUSES(state, payload) {
        state.bookingStatuses = payload
    },
    SET_EDIT_BOOKING_NOMAL_BOOKING(state, payload) {
        state.editNomalBooking = payload
    }
}

const actions = {
    async fetchBookingInfo({state, commit}) {
        const res = await ApiCaller(null, 'user').post(URLS.USER_BOOKING_INFO(), Object.assign({}, state.currentBooking, {
            service_id: state.currentBooking.service.id, therapist_id: state.currentBooking.therapist.id
        }))

        if (res.status == 200 && res.data.status == 'no_valid_room'){
            return 'no_valid_room'
        } else if (res.status == 200 && res.data.status) {
            commit('FILTER_CURRENT_BOOKING', res.data.booking)
            return true
        } else {
            return true
        }
    },

    async fetchAwaitBookingInfo({state, commit}) {
        const res = await ApiCaller(null, 'user').post(URLS.USER_BOOKING_INFO(), Object.assign({}, state.currentBooking, {
            service_id: state.currentBooking.service.id
        }))
        if (res.status == 200 && res.data.status) {
            commit('FILTER_CURRENT_BOOKING', res.data.booking)
            return true
        } else {
            return false
        }
    },

    async fetchBooking({state, commit}, payload) {
        const res =  await ApiCaller(null, 'user').get(URLS.USER_BOOKING(payload))
        if (res.status == 200) {
            let events = res.data.booking.booking_times
            events.forEach( e => {
                Object.assign(e, { formatedTitle: handleBookingTitle(e) })
            })
            commit('FILTER_CURRENT_BOOKING', Object.assign({}, res.data.booking, { selectedEvents: events }))
        }
    },

    async createBooking({state}) {
        await liff.init({ liffId: process.env.LIFF_ID })
        let getAccessToken = liff.getAccessToken()
        const res = await ApiCaller(null, 'user').post(URLS.USER_BOOKINGS(), Object.assign({}, state.currentBooking,
            { option_ids: state.currentBooking.options.map(e => e.id),
                booking_times: state.currentBooking.selectedEvents.map(e => e.start_time),
                therapist_id: state.currentBooking.therapist.id,
                service_id: state.currentBooking.service.id,
                getAccessToken
            }))
        if (res.status == 200) {
            return res.data
        } else {
            return false
        }
    },
    async editBooking({state}, booking_id) {
        const res = await ApiCaller(null, 'user').put(URLS.USER_BOOKING(booking_id), Object.assign({}, state.currentBooking,
            { option_ids: state.currentBooking.options.map(e => e.id),
                booking_times: state.currentBooking.selectedEvents.map(e => e.start_time),
                therapist_id: state.currentBooking.therapist.id,
                booking_id: booking_id,
                service_id: state.currentBooking.service.id
            }))
        if (res.status == 200) {
            return res.data
        } else {
            return false
        }
    },
    async createAwaitBooking({state, dispatch}) {
        const res = await ApiCaller(null, 'user').post(URLS.USER_BOOKINGS(), Object.assign({}, state.currentBooking, {
            option_ids: state.currentBooking.options.map(e => e.id),
            }))
        if (res.status == 200) {
            dispatch('fetchAwaitBookingNumber', res.data.store_id)
            return res.data
        } else {
            return false
        }
    },
    async fetchAwaitBookingNumber({commit}, payload){
        const res = await ApiCaller(null, 'user').get(URLS.ADMIN_CALL_AWAIT_BOOKINGS(), {
              params: {id: payload}
          }
        )
    },
    async createMultipleBooking({state}) {
        const res = await ApiCaller(null, 'user').post(URLS.USER_BOOKINGS(), Object.assign({}, state.currentBooking,
            { option_ids: state.currentBooking.options.map(e => e.id),
                booking_times: state.currentBooking.selectedMultipleEvents.map(e => e.start_time),
                therapist_id: state.currentBooking.therapist.id,
                service_id: state.currentBooking.service.id,
            }))
        if (res.status == 200) {
            return res.data
        } else {
            return false
        }
    },
    async editMultiBooking({state}, booking_id) {
        const res = await ApiCaller(null, 'user').put(URLS.USER_BOOKING(booking_id), Object.assign({}, state.currentBooking,
          { option_ids: state.currentBooking.options.map(e => e.id),
              booking_times: state.currentBooking.selectedMultipleEvents.map(e => e.start_time),
              therapist_id: state.currentBooking.therapist.id,
              booking_id: booking_id,
              service_id: state.currentBooking.service.id
          }))
        if (res.status == 200) {
            return res.data
        } else {
            return false
        }
    },
}


export default {
    namespaced: true,
    state, mutations, actions
}

import { ApiCaller } from '../../utils/api'
import router from '../../user-router'
import { URLS } from '../../utils/url'
import {JWT_STORE_KEY, JWT_USER_KEY} from "../../utils/constant";
import { Message } from 'element-ui'
const state = {
  visibleMember: false,
  userCurrentRegion: null
}
const mutations = {
  SET_VISIBLE_MEMBER(state, payload){
    state.visibleMember = payload
  },
  SET_USER_CURRENT_REGION(state, payload){
    state.userCurrentRegion = payload
  }
}
const actions = {
  async outSystemUser({state, commit}, payload){
    const res = await ApiCaller(null, 'user').post(URLS.OUT_SYSTEM_USER(), payload)
    if (res.data.status){
      await liff.init({ liffId: process.env.LIFF_ID })
      liff.logout()
      localStorage.clear()
      router.push({name: 'SystemOut', query: {region: res.data.region_name}})
    }
  },
  async fetchUserCurrentRegion({state}, payload){
    const response = await ApiCaller(null, 'user').get(URLS.USER_CURRENT_REGION())
    return response
  },
  async loginUser ({ state, commit }){
    const response = await ApiCaller().post(URLS.USER_LOGIN())
    if (response.data.status) {
      localStorage.setItem(JWT_USER_KEY, response.data.token);
      router.push({ name: "UserCategories" });
      Message.success({
        message: response.data.message,
        type: 'success'
      })
    } else {
      Message.error({
        message: response.data.message,
        type: 'error'
      })
    }
  }
}
export default {
  namespaced: true,
  state, mutations, actions
}
import Cookies from 'js-cookie'
import {ApiCaller} from "../../utils/api";
import {URLS} from "../../utils/url";

const state = {
  condition: {
    agreement: false,
  },
  isVisibleParcoConfirm: false,
  hwid: null,
  wid: null,
  isConnectedParco: false,
  isVisibleCamera: false,
  visibleExtenalLink: false,
  isConversionPhase: true,
  visibleModal: false,
  externalLink: '',
  headerFilter: [],
  sidebar: {
    opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
    withoutAnimation: false
  },
  device: 'desktop',
  isActive: false,
  messageObject: {
    message: '',
    type: ''
  },
  size: Cookies.get('size') || 'medium',
  isCollapsed: false,
  current_customer: {},
  current_store: {},
  breadCrumbs:'ダッシュボード',
  modalP1Text: 'LINE認証に失敗しました。',
  modalP2Text: 'お手数ですが、再度アクセスをお願いします',
  isLineToken: true,
  isLoggedIn: false,
  accountType: null
}

const mutations = {
  SET_AUTH_ID(state, payload){
    state.hwid = payload
  },
  SET_WID(state, payload){
    state.wid = payload
  },
  SET_IS_CONVERSION_PHASE(state, payload){
    state.isConversionPhase = payload
  },
  SET_IS_CONNECTED_PARCO(state, payload){
    state.isConnectedParco = payload
  },
  SET_IS_VISIBLE_PARCO_CONFIRM(state, payload){
    state.isVisibleParcoConfirm = payload
  },
  SET_ACCOUNT_TYPE(state, payload){
    state.accountType = payload
  },
  SET_IS_VISIBLE_CAMERA(state, payload){
    state.isVisibleCamera = payload
  },
  SET_EXTERNAL_LINK(state, payload){
    state.externalLink = payload
  },
  SET_VISIBLE_EXTERNAL_LINK(state, payload){
    state.visibleExtenalLink = payload
  },
  SET_IS_LOGGED_IN(state, payload) {
    state.isLoggedIn = payload;
  },
  SET_VISIBLE_MODAL(state, payload){
    state.visibleModal = payload
  },
  SET_IS_LINE_TOKEN(state, payload){
    state.isLineToken = payload
  },
  SET_MODAL_CONTENT(state, payload){
    state.modalP1Text = payload.modalP1Text
    state.modalP2Text = payload.modalP2Text
  },
  SET_IS_ACTIVE(state, payload){
    state.isActive = payload
  },
  SET_MESSAGE(state, payload) {
    let object = { message: payload.message, type: payload.type}
    state.messageObject = object
  },
  SET_BREAD_CUMB(state, payload){
    state.breadCrumbs = payload
  },
  SET_IS_COLLAPSED(state, payload){
    state.isCollapsed = payload
  },
  TOGGLE_SIDEBAR: state => {
    state.sidebar.opened = !state.sidebar.opened
    state.sidebar.withoutAnimation = false
    if (state.sidebar.opened) {
      Cookies.set('sidebarStatus', 1)
    } else {
      Cookies.set('sidebarStatus', 0)
    }
  },
  CLOSE_SIDEBAR: (state, withoutAnimation) => {
    Cookies.set('sidebarStatus', 0)
    state.sidebar.opened = false
    state.sidebar.withoutAnimation = withoutAnimation
  },
  TOGGLE_DEVICE: (state, device) => {
    state.device = device
  },
  SET_SIZE: (state, size) => {
    state.size = size
    Cookies.set('size', size)
  },
  SET_CURRENT_STORE(state, payload) {
    state.current_store = payload
  }
}

const actions = {
  toggleSideBar({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  closeSideBar({ commit }, { withoutAnimation }) {
    commit('CLOSE_SIDEBAR', withoutAnimation)
  },
  toggleDevice({ commit }, device) {
    commit('TOGGLE_DEVICE', device)
  },
  setSize({ commit }, size) {
    commit('SET_SIZE', size)
  },
  async fetchCurrentStore({ state, commit }){
    const response = await ApiCaller(null, true).get(URLS.CURRENT_STORE())
    commit('SET_CURRENT_STORE', response.data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
